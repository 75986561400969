import '../scss/styles.scss'
import $ from 'jquery'
import 'jquery-sticky'
import debounce from 'underscore/cjs/debounce'
import isTouchDevice from 'helpers/touch_device_test'

class MainNavigation {
    constructor(elem) {
        this.elem = $(elem);
        this.menu = $('.main-nav-wrapper', this.elem);

        this._make_main_nav_sticky();
        this.main_menu_entries = $('.drop-down-menu > li', this.elem);

        this._attach_events();

        this._are_menus_open = false;

    }

    close_menus() {
        if (this._are_menus_open) {
            this.main_menu_entries.removeClass('active');
        }
    }

    _make_main_nav_sticky() {
        this.menu.sticky();
    }

    _align_menu($anchor, $menu_wrapper) {
        const $menu = $menu_wrapper.find('ul');

        const window_width = $(window).width();
        const anchor_offset_left = $anchor.offset().left;
        const menu_width = $menu.width();
        const anchor_offset_right = $anchor.offset().left + $anchor.width() - menu_width;


        if (anchor_offset_left + menu_width > window_width) {
            $menu.css('left', anchor_offset_right);
        } else {
            $menu.css('left', anchor_offset_left);
        }
    }

    _attach_events() {
        this.main_menu_entries.find('a').on(isTouchDevice?'click':'click mouseenter', (e) => {
            const $anchor = $(e.currentTarget);
            const $menu_list_item = $anchor.closest('li');

            if ($menu_list_item.find('.submenu').length) {
                if ($menu_list_item.hasClass('active')) {
                    // if the current menu is already opened trigger the normal click
                    // no further actions needed
                } else {
                    // otherwise open it up
                    this._are_menus_open = true;
                    e.preventDefault();
                    this.close_menus();
                    $menu_list_item.addClass('active');
                    this._align_menu($anchor, $menu_list_item);
                }

            }
        });

        // close menu's on
        $(window).on('resize scroll', debounce(this.close_menus.bind(this), 250, true))
        $('body').on('click', this.close_menus.bind(this));
        if (! isTouchDevice ) {
            this.menu.on('mouseleave', this.close_menus.bind(this));
        }
        // prevent closing menus on menu-click
        this.menu.on('click', (e) => {e.stopPropagation();})
    }
}
$(function() {

    const main_navitation = new MainNavigation($('.page-header-navigation'))


    $('.main-mobile-navigation button').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();

        $('#nav-overlay').addClass('visible');
    })

    $(  '.main-mobile-navigation .main-navigation a,' +
        '.main-mobile-navigation button.close-overlay')
        .on('click', function(){
        $('#nav-overlay').removeClass('visible');
    })
});

