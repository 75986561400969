import '../scss/styles.scss';

let pow_result = null;

window.addEventListener('load', function() {
    const forms = document.querySelectorAll('.newsletter-registration form');
    if (!forms.length) return;
  
    const formData = new FormData(forms[0]);
    const token = formData.get('token');
    const difficulty = parseInt(formData.get('difficulty'));
    const count = parseInt(formData.get('count'));
    pow_result = pow_bit(token, difficulty, count);
  
    for (const form of forms) {
        form.addEventListener('submit', submitHandler);
    }
});


async function submitHandler(evt) {
    if (evt.preventDefault) evt.preventDefault();
    const form = evt.target;
    const parent = form.parentElement;
    const formData = new FormData(form);
    parent.querySelector('.loader-container').style.display = 'block';
    form.style.display = 'none';
    formData.set('nonces', JSON.stringify(await pow_result));
    fetch(form.action, {body: formData, method: "post"})
        .then(resp => {
            parent.querySelector('.loader-container').style.display = 'none';
            parent.querySelector(resp.status === 201 ? '.success' : '.failure').style.display = 'block';
        })
        .catch(e => {
            parent.querySelector('.loader-container').style.display = 'none';
            parent.querySelector('.failure').style.display = 'block';
        })
    return false;
}


async function pow_bit(data, bits, count) {
    if (bits >= 32) throw new Error('bits must be below 32');
    if (count >= 16) throw new Error('count must be below 16');
    const token = new TextEncoder().encode(data);
    const input = new Uint8Array(token.length + 10);
    input.set(token);
    const mask = (1 << bits) - 1;
    const nonces = new Array(count);
    const check = Array.from(nonces).map((_,i) => i);
    let nonce = 0;
    while (check.length) {
        const chars = '' + ++nonce;
        for (let i = 0; i < chars.length; ++i){
            input[token.length + i] = chars.charCodeAt(i);
        }
        const hash = new Uint32Array(
            await crypto.subtle.digest('SHA-512', input.subarray(0, token.length + chars.length))
        );
        for (let i = 0; i < check.length; ++i) {
            if (!(hash[check[i]] & mask)) {
                nonces[check[i]] = nonce;
                check.splice(i, 1);
                i--;
            }
        }
    }
    return nonces;
}
