import "../scss/styles.scss"
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'
import $ from 'jquery'

const galleries = []

$(function () {
        const $gallery_items = document.querySelectorAll('.gallery-items');
        for (const $gallery_item of $gallery_items) {
                const gallery = new Viewer(
                    $gallery_item,
                    {
                        title: (image, image_data) => {
                            const data = gallery.images[gallery.index].dataset;
                            if (data['title'] || data['caption'] || data['author']) {
                                return `<div class="caption-wrapper"><h1>${data['title']}</h1><p>${data['caption']}</p><em>${data['author']}</em></div>`
                            } else {
                                return ""
                            }

                        },
                        url: (image, image_data) => {
                            const data = image.dataset;
                            return data['fullSrc']
                        },
                        tooltip: false,
                        toolbar: {
                            zoomIn: false,
                            zoomOut: false,
                            oneToOne: false,
                            reset: false,
                            prev: 4,
                            play: {
                                show: false,
                                size: 'large',
                            },
                            next: 4,
                            rotateLeft: false,
                            rotateRight: false,
                            flipHorizontal: false,
                            flipVertical: false,
                        }

                    }
                );
                galleries.push(gallery);
        }
    }
);

