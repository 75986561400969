import $ from "jquery"
import InView from "helpers/in-view"
import "scss/primitives/_lazy_image.scss"

const SIZE_CLASSES = {
    small: 640,
    medium: 1024,
    large: 1152,
    full: 1400,
};


class LazyImage extends InView {
    constructor(elem, urls) {
        super(elem);
        this.urls = urls;
    };

    get image_dimension() {
        let $elem = $(this.elem);
        if ($elem.outerHeight == 0 || $elem.outerWidth() == 0) {
            return 0;
        };

        return Math.min(window.devicePixelRatio, 2) * Math.max($elem.outerWidth(), $elem.outerHeight());
    };

    get image_url() {
        const outer_size = this.image_dimension;
        const sorted_size_keys = Object.keys(SIZE_CLASSES).sort(function(a,b){return SIZE_CLASSES[a]-SIZE_CLASSES[b]})
        var image_class = sorted_size_keys[0];
        for (let idx in sorted_size_keys) {
            if (outer_size >= SIZE_CLASSES[sorted_size_keys[idx]]){
                // console.log(idx, sorted_size_keys, sorted_size_keys[parseInt(idx) + 1]);
                image_class = sorted_size_keys[parseInt(idx) + 1];
            }
        };
        image_class = image_class || sorted_size_keys[sorted_size_keys.length-1];


        return this.urls[image_class];
    };
    replace_image = () => {
        var image = new Image();
        image.src = this.image_url;
        image.onload = function(){
            if (this.elem.tagName == 'IMG')
            {
                $(this.elem).attr('src', image.src);

            } else {
                $(this.elem).css('background-image', `url('${image.src}')`)
            }
            $(this.elem).addClass('image-ready');
        }.bind(this);
    };
    enters_cb = (count, down_scroll) => {
        this.replace_image();
        this.unregister_scroll_handler();
    };
    visible_cb = this.enters_cb;
}


export default LazyImage
