import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'
import '../scss/stage.scss'

$(function() {
    $('.stage .slider').slick({
        autoplay: 1500,
        arrows: false,
        dots: false
    });
});
