import '../scss/_upcoming_events.scss'
import $ from 'jquery'

const TAG_STATE = {
    SELECTED: 0,
    UNSELECTED: 1,
    DISABLED: 2,
}

class TaggedElement {
    constructor(elem) {
        this.elem = $(elem)
        this.tags = this.elem.data('hasTags').split(' ')
    }

    has_tag(tag) {
        return this.tags.includes(tag);
    }

    has_tags(tags) {
        return tags.reduce(
            (a, tag) => (
                this.tags.includes(tag) || a
            ),
            false)
    }

    set active(value) {
        if (value) this.elem.removeClass('inactive')
        else this.elem.addClass('inactive')
    }

    get active() {
        return !this.elem.hasClass('inactive')
    }
}

class TagButton {
    constructor(elem, toggle_callback) {
        this.elem = $(elem)
        this.toggle_callback = toggle_callback
        this.tag = this.elem.data('tag')
        this.toggle_callback = toggle_callback
        this._bind_events()
    }


    _click_handler(e) {
        e.preventDefault();
        switch (this.state) {
            case TAG_STATE.SELECTED :
                this.state = TAG_STATE.UNSELECTED
                break
            case TAG_STATE.UNSELECTED :
                this.state = TAG_STATE.SELECTED
                break
        }
        this.toggle_callback(this.tag, this.state, this.elem)
    }

    _bind_events() {
        this.elem.on('click', this._click_handler.bind(this))
    }

    get state() {
        if (this.elem.is(':disabled')) {
            return TAG_STATE.DISABLED
        }
        if (this.elem.hasClass('unselected')) {
            return TAG_STATE.UNSELECTED
        }
        return TAG_STATE.SELECTED
    }

    set state(new_state) {
        switch (new_state) {
            case TAG_STATE.DISABLED:
                this.elem
                    .prop('disabled', true)
                    .removeClass('unselected')
                    .removeClass('selected')
                break;

            case TAG_STATE.UNSELECTED:
                this.elem
                    .prop('disabled', false)
                    .addClass('unselected')
                    .removeClass('selected')
                break;

            case TAG_STATE.SELECTED:
                this.elem
                    .prop('disabled', false)
                    .removeClass('unselected')
                    .addClass('selected')
                break;
        }
    }

}

class TagButtonGroup {
    constructor(elem) {
        this._change_callback = () => {
        };
        this.buttons = $('[data-tag]', elem).toArray().map((e, i) => (new TagButton(e, this._exec_change_callback.bind(this))))
    }

    on_change(cb) {
        this._change_callback = cb;
        return this
    }

    _exec_change_callback() {
        this._change_callback(...arguments)
    }

    get length() {
        return this.buttons.length
    }

    get selected_tags() {
        const tags = new Set();
        return this.buttons.reduce(
            (a, e) => {
                if (e.state == TAG_STATE.SELECTED) {
                    a.add(e.tag)
                }
                return a
            },
            new Set()
        )
    }

    set selected_tags(tag_names){
        this.buttons.forEach(
            (b)=>{
                if (tag_names.includes(b.tag)) {
                    b.state = TAG_STATE.SELECTED
                } else {
                    b.state = TAG_STATE.UNSELECTED
                }
            }
        )
    }
}

class EventFilter {
    constructor(elem) {
        this.elem = $(elem)
        this.grid_items = $('[data-has-tags]', this.elem).toArray().map((e, i) => (new TaggedElement(e)))
        this.tags = new TagButtonGroup(this.elem)
            .on_change(this.toggleTag.bind(this))
    }

    toggleTag(tag, new_state, button) {
        const selected_tags = this.tags.selected_tags
        if (
            (new_state == TAG_STATE.UNSELECTED) &&
            (selected_tags.size == this.tags.length - 1)
        )
        {

            // all tags were selected, deselect all others
            this.tags.selected_tags = [tag, ]
            this.grid_items.forEach(
                (e)=>{
                    if (!e.has_tag(tag)){
                        e.active = false
                    }
                })
            button.selected=true
        }
        else if (selected_tags.size == 0) // if the last tag is deselected – select all tags again ;)
        {
            this.tags.buttons.map((e,i)=>{e.state = TAG_STATE.SELECTED})
            this.grid_items.forEach(
                (e)=>{
                    e.active = true
                })
        }
        else
        {
            this.grid_items.forEach(
                (e) => {
                    e.active = false
                    selected_tags.forEach((t) => {
                        if (e.has_tag(t)) {
                            e.active = true
                        }
                    })
                }
            )
        }

        // trigger any inview-component to recheck if in view:
        $(window).trigger('refresh.inview');
    }
}


$(function () {
    $('.event-calendar').each(function () {
        const filter_elem = new EventFilter(this)
    })
})
