import $ from 'jquery'
import throttle from 'underscore/cjs/throttle'

/*
 * Example:
 *
 * import my_in_view from "in-view"
 *
 * var bla = new my_in_view($elem);
 * bla.visible((count)=>{
 *    console.log('$elem is fully visible in viewport');
 *  })
 *  .enters((count, ds)=> {
 *    console.log('$elem enters the viewport');
 *  })
 *  .leaves((count, ds)=> {
 *    console.log('$elem leaves the viewport');
 *  })
 *
 */

const STATE_UNDEFINED = -1;
const STATE_ENTERS = 0;
const STATE_LEAVES = 1;
const STATE_VISIBLE = 2;


class InView {
    constructor(elem) {
        this.elem = elem;
        this.visible_count = 0;
        this.enters_count = 0;
        this.leaves_count = 0;
        this.visible_cb = ()=>{};
        this.enters_cb = ()=>{};
        this.leaves_cb = ()=>{};
        this._last_scroll_top = -1;
        this._last_state = STATE_UNDEFINED;

        $(window).on('scroll.inview', this._check_visible);
        $(window).on('resize.inview', this._check_visible);
        $(window).on('refresh.inview', this._check_visible);
        this._check_visible();
    };
    destroy() {
        this.unregister_scroll_handler();
        this.unregister_resize_handler();
    };

    unregister_resize_handler = function() {
        $(window).off('resize.inview', this._check_visible);
    };

    unregister_scroll_handler = function() {
        $(window).off('scroll.inview', this._check_visible);
    };

    _check_visible = throttle(function(){
        // check enters on scroll down
        if (this._last_state != STATE_ENTERS && this._last_state != STATE_VISIBLE
            && this.down_scroll
            && this.window_scroll_offset + this.window_height >= this.elem_top_offset
            && this.window_scroll_offset < this.elem_bottom_offset ){
            this.enters_count++;
            this._last_state = STATE_ENTERS;
            this.enters_cb(this.enters_count, this.down_scroll);
        }
        // check if enters on scoll up
        else if (this._last_state != STATE_ENTERS && this._last_state != STATE_VISIBLE
            && !this.down_scroll
            && this.window_scroll_offset <= this.elem_bottom_offset
            && this.window_scroll_offset >= this.elem_top_offset ){

            this.enters_count++;
            this._last_state = STATE_ENTERS;
            this.enters_cb(this.enters_count, this.down_scroll);
        }
        // check leaves on scroll down
        else if (this._last_state != STATE_LEAVES
            && this.down_scroll
            && this.window_scroll_offset >= this.elem_bottom_offset){

            this.leaves_count++;
            this._last_state = STATE_LEAVES;
            this.leaves_cb(this.leaves_count, this.down_scroll);
        }
        // check leaves on scroll up
        else if (this._last_state != STATE_LEAVES
            && !this.down_scroll
            && this.window_scroll_offset + this.window_height <= this.elem_top_offset ) {

            this.leaves_count++;
            this._last_state = STATE_LEAVES;
            this.leaves_cb(this.leaves_count, this.down_scroll);
        }

        // check if fully visible
        else if (this._last_state != STATE_VISIBLE
            && this.window_scroll_offset <= this.elem_top_offset
            && (this.window_scroll_offset + this.window_height) >= this.elem_bottom_offset) {

            this.visible_count++;
            this._last_state = STATE_VISIBLE;
            this.visible_cb(this.visible_count);
        }

        this._last_scroll_top = this.window_scroll_offset;
    }.bind(this), 25, {leading: false});

    visible = function (cb) {
        this.visible_cb = cb;
        return this;
    };

    enters = function (cb) {
        this.enters_cb = cb;
        return this;
    };

    leaves = function (cb) {
        this.leaves_cb = cb;
        return this;
    };

    get down_scroll() {
        return this._last_scroll_top <= this.window_scroll_offset;
    };
    get up_scroll() {
        return !this.down_scroll;
    }
    get window_scroll_offset() {
        return (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
    };
    get window_height() {
        return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    };
    get elem_height() {
        return $(this.elem).height()
    };
    get elem_bottom_offset() {
        return this.elem_top_offset + this.elem_height;
    };
    get elem_top_offset() {
        return $(this.elem).offset().top
    }
}

export default InView
