import $ from "jquery";
import "../scss/styles.scss";
import InView from "helpers/in-view";

$(function() {
    $('.videoplayer').each((i, video_player)=>{
        const iframe = $('[data-lazy-src]', video_player)
        const allow_button = $('.button.allow', video_player)

        allow_button.on('click', function(e) {
            e.preventDefault();
            const player = new InView(iframe).enters(()=>{
                $(iframe).attr('src', $(iframe).data('lazySrc')).addClass('loaded').closest('.placeholder-image').addClass('hide-image');
                player.destroy();
            })
        })
    })
})
