import '../scss/styles.scss'
import $ from 'jquery'

import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.js'
import icon_svg from 'header/static/kulturschlachthof_logo_badge.svg'

const MAP_URL = window.MAP_URL||'https://kulturschlachthof-jena.de/tiles/{z}/{x}/{y}.png'

$(window).on('load', function () {
    $('.map-wrapper').each(function () {
        const $this = $(this)
        const map_id = $this.attr('id')

        const center_lat = $this.data('center-lat') || 50.938910
        const center_lng = $this.data('center-lng') || 11.598921
        const pin_lat = $this.data('pin-lat') || center_lat
        const pin_lng = $this.data('pin-lng') || center_lng
        const zoom_level = $this.data('zoom-level') || 17
        const popup_content = $this.data('popup-content')

        const icon = L.icon({
            iconUrl: icon_svg,
            iconSize: [100, 100],
            iconAnchor: [50, 50],
            popupAnchor: [0, -50]
        });

        const map = L.map(map_id, {
            zoomControl: false,
            fullscreenControl: false
        }).setView([center_lat, center_lng], zoom_level)

        const tile = L.tileLayer(MAP_URL, {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        const marker = L.marker([pin_lat, pin_lng], {icon: icon}).addTo(map);

        if (popup_content) {
            const popup = marker.bindPopup(popup_content).openPopup();
        }
    })
});

